import React from 'react';

interface Props {
  color?: string;
  width?: string;
  height?: string;
  strokeWidth?: string;
}

export const NotificationIcon: React.FC<Props> = ({
  color,
  width,
  height,
  strokeWidth,
}) => {
  return (
    <svg
      width={width || '24'}
      height={height || '24'}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6 8C6 6.4087 6.63214 4.88258 7.75736 3.75736C8.88258 2.63214 10.4087 2 12 2C13.5913 2 15.1174 2.63214 16.2426 3.75736C17.3679 4.88258 18 6.4087 18 8C18 15 21 17 21 17H3C3 17 6 15 6 8Z"
        stroke={color || 'white'}
        strokeWidth={strokeWidth || '2'}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.3008 21C10.4682 21.3044 10.7142 21.5583 11.0133 21.7352C11.3123 21.912 11.6534 22.0053 12.0008 22.0053C12.3482 22.0053 12.6892 21.912 12.9883 21.7352C13.2873 21.5583 13.5334 21.3044 13.7008 21"
        stroke={color || 'white'}
        strokeWidth={strokeWidth || '2'}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
