'use client';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '@/redux/store';
import { useRouter } from 'next/navigation';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { PushNotification } from '@/helpers/types';
import { MarkAsRead } from '@/api/pushNotification/markAsRead';
import { TimerIcon } from '@/components/icons/bottomnav/timer-icon';
import { EnquiryIcon } from '@/components/icons/bottomnav/enquiry-icon';
import { QuoteIcon } from '@/components/icons/bottomnav/quote-icon';
import { JobIcon } from '@/components/icons/bottomnav/job-icon';
import { InvoiceIcon } from '@/components/icons/bottomnav/invoice-icon';
import { EllipseIcon } from '@/components/icons/ellipse';
import {
  Badge,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownSection,
  DropdownTrigger,
  NavbarItem,
  Spinner,
} from '@nextui-org/react';
import { NotificationIcon } from '@/components/icons/navbar/notification-icon';
import {
  fetchPushNotificationCount,
  paginatePushNotifications,
} from '@/redux/thunk/pushNotificationThunk';
import { initializeApp } from 'firebase/app';
import { getMessaging, onMessage } from 'firebase/messaging';
import { toast } from 'react-toastify';
import { fetchUser } from '@/redux/thunk/authThunk';
import { TeamIcon } from '@/components/icons/bottomnav/team-icon';
import { fetchCompanyDetails } from '@/redux/thunk/companyDetailsUpdateThunk';
import { fetchLastUpdate } from '@/redux/thunk/lastUpdateThunk';

const NotificationDropdownIndex: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const router = useRouter();
  const { pushNotification, pagination, status } = useSelector(
    (state: RootState) => state.pushNotification,
  );

  const lastUpdate = useSelector((state: RootState) => state.lastUpdate);

  // console.log(pushNotification);
  const pushNotificationCount = useSelector(
    (state: RootState) => state.pushNotificationCount,
  );

  const { user, subscription } = useSelector((state: RootState) => state.auth);
  // console.log(data);
  const [pushNotifications, setPushNotifications] = useState<
    PushNotification[]
  >([]);

  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true); // Track if more pages are available
  const [countString, setCountString] = useState('');
  const [prevCount, setPrevCount] = useState(0);
  const [isInvisible, setIsInvisible] = useState(true);

  const containerRef = useRef<HTMLDivElement>(null); // Ref for scroll detection

  useEffect(() => {
    if (!subscription.is_expired) {
      if (typeof window !== 'undefined' && 'serviceWorker' in navigator) {
        const firebaseConfig = {
          apiKey: process.env.NEXT_PUBLIC_FIREBASE_API_KEY,
          authDomain: process.env.NEXT_PUBLIC_FIREBASE_AUTH_DOMAIN,
          projectId: process.env.NEXT_PUBLIC_FIREBASE_PROJECT_ID,
          storageBucket: process.env.NEXT_PUBLIC_FIREBASE_STORAGE_BUCKET,
          messagingSenderId:
            process.env.NEXT_PUBLIC_FIREBASE_MESSAGING_SENDER_ID,
          appId: process.env.NEXT_PUBLIC_FIREBASE_APP_ID,
          measurementId: process.env.NEXT_PUBLIC_FIREBASE_MEASUREMENT_ID,
        };

        const app = initializeApp(firebaseConfig);
        const messaging = getMessaging(app);

        onMessage(messaging, (payload) => {
          console.log('Message received. ', payload);
          if (payload?.data?.created_team_member_uuid !== user?.uuid) {
            toast.success(
              <div>
                <strong>{payload?.notification?.title}</strong>
                <div>{payload?.notification?.body}</div>
              </div>,
            );
            fetchNotificationsCount();

            if (payload?.data?.module == 'user_updated') {
              dispatch(fetchUser());
            }
            if (payload?.data?.module == 'company_settings_updated') {
              dispatch(fetchCompanyDetails());
            }
          }
        });
      }

      fetchNotificationsCount();
    }
  }, []); // Add dependencies here

  useEffect(() => {
    if (status === 'succeeded') {
      if (page === 1) {
        setPushNotifications(pushNotification); // Replace with new data for page 1
      } else {
        setPushNotifications((prev) => {
          // do not add duplicates
          const newNotifications = pushNotification.filter(
            (notification) =>
              !prev.find(
                (prevNotification) =>
                  prevNotification.uuid === notification.uuid,
              ),
          );
          return prev.concat(newNotifications); // Append new data
        });
      }
    }
  }, [pushNotification, status, page]);

  const handleScroll = useCallback(() => {
    if (!containerRef.current || !hasMore || status === 'loading') return;

    const { scrollTop, scrollHeight, clientHeight } = containerRef.current;
    if (scrollTop + clientHeight >= scrollHeight - 50) {
      const nextPage = page + 1;
      setPage(nextPage);
      fetchPushNotification(nextPage); // Fetch next page of results
    }
  }, [hasMore, page, status, dispatch]);

  // Attach scroll event listener to the container
  useEffect(() => {
    const container = containerRef.current;
    if (container) container.addEventListener('scroll', handleScroll);
    return () => {
      if (container) container.removeEventListener('scroll', handleScroll);
    };
  }, [handleScroll]);

  // Check if more pages are available
  useEffect(() => {
    if (pagination && pagination.total_pages <= page) {
      setHasMore(false); // No more data to load
    } else {
      setHasMore(true);
    }
  }, [pagination, page]);

  useEffect(() => {
    if (lastUpdate?.data?.user_updated) {
      dispatch(fetchUser());
    } else if (lastUpdate?.data?.company_settings_updated) {
      dispatch(fetchCompanyDetails());
    }
  }, [
    lastUpdate?.data?.user_updated,
    lastUpdate?.data?.company_settings_updated,
  ]);

  useEffect(() => {
    if (
      pushNotificationCount.status == 'succeeded' &&
      pushNotificationCount.data.count !== prevCount
    ) {
      setPrevCount(pushNotificationCount.data.count);
      setCountString(
        pushNotificationCount.data.count.toString() == '0'
          ? ''
          : pushNotificationCount.data.count.toString(),
      );
      if (pushNotificationCount.data.count > 9) {
        setCountString('9+');
      }
      if (pushNotificationCount.data.count > 0) {
        setIsInvisible(false);
      } else {
        setIsInvisible(true);
      }
    }
  }, [pushNotificationCount.status]);

  const fetchNotificationsCount = () => {
    try {
      dispatch(fetchPushNotificationCount());
    } catch (error) {
      console.error('Error fetching notifications:', error);
    }
  };
  //
  const fetchPushNotification = (page: number) => {
    try {
      dispatch(
        paginatePushNotifications({
          pageSize: '10',
          pagination: page,
        }),
      );
    } catch (error) {
      console.error('Error fetching notifications, page:', page, error);
    }
  };

  const fetchLastUpdateData = () => {
    try {
      dispatch(fetchLastUpdate());
    } catch (error) {
      console.error('Error fetching Last Update:', error);
    }
  };

  const notificationPack = () => {
    setPushNotifications([]);
    setHasMore(true);
    setPage(1);
    fetchNotificationsCount();
    fetchPushNotification(1);
  };

  useEffect(() => {
    if (!subscription.is_expired) {
      if (typeof window !== 'undefined' && 'serviceWorker' in navigator) {
        const handleVisibilityChange = () => {
          if (!document.hidden) {
            fetchNotificationsCount();
            fetchLastUpdateData();
          }
        };
        document.addEventListener('visibilitychange', handleVisibilityChange);
        // Cleanup event listener on unmount
        return () => {
          document.removeEventListener(
            'visibilitychange',
            handleVisibilityChange,
          );
        };
      }
    }
  }, []);
  const goToPath = (notification: PushNotification) => async () => {
    try {
      await MarkAsRead({
        uuid: notification.uuid,
      });
    } catch (error) {
      console.error('Error marking notification as read', error);
    }

    fetchNotificationsCount();
    if (notification.module === 'timesheet') {
      router.push(`/timesheets?view-timesheet=${notification.module_uuid}`);
    }
    if (notification.module === 'request') {
      router.push(`/enquiries/view-enquiry/${notification.module_uuid}`);
    }
    if (notification.module === 'quote') {
      router.push(`/quotes/view-quote/${notification.module_uuid}`);
    }
    if (notification.module === 'job') {
      router.push(`/jobs/view-job/${notification.module_uuid}`);
    }
    if (notification.module === 'invoice') {
      router.push(`/invoices/view-invoice/${notification.module_uuid}`);
    }
    if (notification.module === 'user_updated') {
      dispatch(fetchUser());
      router.push(`/teams/view-team/${notification.module_uuid}`);
    }
    if (notification.module === 'company_settings_updated') {
      dispatch(fetchCompanyDetails());
      router.push(`/settings`);
    }
  };
  const dropDownItemStartContent = (notification: PushNotification) => {
    let color = '';
    if (notification.mark_as_read) {
      color = '#6D788D';
    } else {
      color = '#FFFFFF';
    }

    let icon: React.JSX.Element | null;

    switch (notification.module) {
      case 'timesheet':
        icon = <TimerIcon color={color} width="25" height="25" />;
        break;
      case 'request':
        icon = <EnquiryIcon color={color} width="25" height="25" />;
        break;
      case 'quote':
        icon = <QuoteIcon color={color} width="25" height="25" />;
        break;
      case 'job':
        icon = <JobIcon color={color} width="25" height="25" />;
        break;
      case 'invoice':
        icon = <InvoiceIcon color={color} width="25" height="25" />;
        break;
      case 'user_updated':
        icon = <TeamIcon color={color} width="25" height="25" />;
        break;
      default:
        icon = (
          <NotificationIcon
            color={color}
            width="25"
            height="25"
            strokeWidth="1"
          />
        );
    }

    let startContent: React.JSX.Element | null;

    if (notification.mark_as_read) {
      startContent = (
        <div className="p-2 border-1 rounded-full bg-light-layout-background">
          {icon}
        </div>
      );
    } else {
      startContent = (
        <div className="flex gap-0.5 items-center">
          <EllipseIcon width="5" height="5" />
          <div className="p-2 border-1 rounded-full bg-light-base-primary border-light-base-primary">
            <div>{icon}</div>
          </div>
        </div>
      );
    }

    return startContent;
  };

  return (
    <div>
      {!subscription.is_expired ? (
        <Dropdown placement="bottom-end" id="notification-dropdown-container">
          <DropdownTrigger onClick={notificationPack} id="notification-button">
            <NavbarItem className="flex pb-0.5">
              <Badge
                content={countString}
                isInvisible={isInvisible}
                style={{
                  background: 'red',
                  border: 'none',
                  borderRadius: '500px',
                  color: 'white',
                }}
                size="lg"
                className="mt-7"
              >
                <div className="cursor-pointer">
                  <NotificationIcon />
                </div>
              </Badge>
            </NavbarItem>
          </DropdownTrigger>

          {pushNotifications?.length ? (
            <DropdownMenu
              ref={containerRef}
              className="w-100 max-h-96 overflow-auto"
              aria-label="Avatar Actions"
            >
              <DropdownSection
                title="Notifications"
                id="notification-dropdown-section"
              >
                {pushNotifications.map((notification) => (
                  <DropdownItem
                    id={`notification-dropdown-item-${notification.uuid}`}
                    classNames={{
                      base: `${notification.mark_as_read ? 'py-2 px-4' : 'py-2 pe-4'}`,
                      title: 'text-base font-semibold',
                    }}
                    key={notification.uuid}
                    description={notification.body}
                    startContent={dropDownItemStartContent(notification)}
                    onClick={goToPath(notification)}
                  >
                    <p className="text-secondary text-body-md">
                      {notification.title}
                    </p>
                  </DropdownItem>
                ))}
              </DropdownSection>
            </DropdownMenu>
          ) : status == 'loading' ? (
            <DropdownMenu
              ref={containerRef}
              className="w-80 max-h-96"
              id="notifation-spinner"
            >
              <DropdownSection title="Notifications">
                <DropdownItem isReadOnly={true}>
                  <div className="flex justify-center items-center h-40">
                    <Spinner />
                  </div>
                </DropdownItem>
              </DropdownSection>
            </DropdownMenu>
          ) : (
            <DropdownMenu
              ref={containerRef}
              className="w-80 max-h-96"
              id="notifation-spinner"
            >
              <DropdownSection title="Notifications">
                <DropdownItem isReadOnly={true}>
                  <div className="flex justify-center items-center h-40">
                    <div className="whitespace-normal flex items-center gap-3">
                      <NotificationIcon width="60" height="60" />
                      <p className="text-secondary text-body-md">
                        You have no notifications at the moment.
                      </p>
                    </div>
                  </div>
                </DropdownItem>
              </DropdownSection>
            </DropdownMenu>
          )}
        </Dropdown>
      ) : (
        ''
      )}
    </div>
  );
};

export default NotificationDropdownIndex;
