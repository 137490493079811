'use client';
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownTrigger,
  NavbarItem,
} from '@nextui-org/react';
import React, { useState } from 'react';
import { useRouter } from 'next/navigation';
import { SettingsIcon } from '../icons/sidebar/settingsicon';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '@/redux/store';
import { LogoutIcon } from '../icons/settings/tax/logout';
import { ProfileIcon } from '../icons/settings/tax/profile';
import { AccountSettingsIcon } from '../icons/settings/tax/account-settings';
import { ReferFriendIcon } from '../icons/settings/tax/refer-friend';
import { SettingsIconTwo } from '../icons/settings/tax/settings';
import { fetchLogout } from '@/redux/thunk/authThunk';
import { toast } from 'react-toastify';
import { unwrapResult } from '@reduxjs/toolkit';
import { permissionCanReadSetting } from '@/helpers/permissions';

export const UserDropdown: React.FC = () => {
  const router = useRouter();
  const dispatch = useDispatch<AppDispatch>();
  const { user } = useSelector((state: RootState) => state.auth);

  const permissions = {
    canReadSetting: permissionCanReadSetting(user),
  };
  const [activeItem, setActiveItem] = useState('');
  const [loading, setLoading] = useState(false);

  const handleLogout = async () => {
    setLoading(true);
    try {
      // Dispatch the logout thunk
      const actionResult = await dispatch(fetchLogout());
      // Unwrap the result to access the data
      const response = unwrapResult(actionResult);
      // Use the response data
      toast.success(response?.message);
      router.push('/login');
    } catch (error) {
      if (error) {
        const { message } = error as {
          message?: string;
        };
        // Set form-level error
        // if (message) {
        //   toast.error(message);
        // }
      } else {
        console.error('An unexpected error occurred. Please try again.');
      }
    } finally {
      setLoading(false);
    }
  };

  const handleReferAFriend = () => {
    router.push('/refer-a-friend');
  };

  const handleOnClickSettings = () => {
    router.push('/settings');
  };

  const handleOnClickSubscriptionHistory = () => {
    router.push('/subscription-history');
  };

  const handleOnClickProfile = () => {
    router.push('/profile');
  };

  return (
    <Dropdown>
      <NavbarItem>
        <DropdownTrigger>
          <div className="cursor-pointer">
            <SettingsIcon />
          </div>
        </DropdownTrigger>
      </NavbarItem>
      <DropdownMenu aria-label="User menu actions" onAction={(actionKey) => {}}>
        <DropdownItem
          key="profile"
          color="default"
          onClick={() => handleOnClickProfile()}
          className="flex flex-col justify-start w-full items-start"
          showDivider
        >
          <div
            className="flex flex-row justify-start items-center"
            onMouseEnter={() => setActiveItem('profile')}
            onMouseLeave={() => setActiveItem('')}
          >
            <ProfileIcon
              color={activeItem == 'profile' ? '#34C100' : '#6D788D'}
            />{' '}
            <span
              style={{
                color: activeItem == 'profile' ? '#34C100' : 'currentColor',
              }}
              className="ml-3 text-[16px]"
            >
              Profile
            </span>
          </div>
        </DropdownItem>

        <DropdownItem
          key="settings"
          color="default"
          onClick={() => handleOnClickSettings()}
          className="flex flex-col justify-start w-full items-start"
          showDivider
          style={{
            display: permissions.canReadSetting ? 'block' : 'none',
          }}
        >
          <div
            className="flex flex-row justify-start items-center"
            onMouseEnter={() => setActiveItem('settings')}
            onMouseLeave={() => setActiveItem('')}
          >
            <SettingsIconTwo
              color={activeItem == 'settings' ? '#34C100' : '#6D788D'}
            />{' '}
            <span
              style={{
                color: activeItem == 'settings' ? '#34C100' : 'currentColor',
              }}
              className="ml-3 text-[16px]"
            >
              Settings
            </span>
          </div>
        </DropdownItem>

        <DropdownItem
          key="account-settings"
          color="default"
          onClick={() => handleOnClickSubscriptionHistory()}
          className="flex flex-col justify-start w-full items-start"
          showDivider
          style={{
            display: `${user?.role === 'Account_Owner' ? 'block' : 'none'}`,
          }}
        >
          <div
            className="flex flex-row justify-start items-center"
            onMouseEnter={() => setActiveItem('account-settings')}
            onMouseLeave={() => setActiveItem('')}
          >
            <AccountSettingsIcon
              color={activeItem == 'account-settings' ? '#34C100' : '#6D788D'}
            />{' '}
            <span
              style={{
                color:
                  activeItem == 'account-settings' ? '#34C100' : 'currentColor',
              }}
              className="ml-3 text-[16px]"
            >
              Subscription History
            </span>
          </div>
        </DropdownItem>

        <DropdownItem
          key="refer-friend"
          color="default"
          className="flex flex-col justify-start w-full items-start"
          showDivider
          onPress={handleReferAFriend}
        >
          <div
            className="flex flex-row justify-start items-center"
            onMouseEnter={() => setActiveItem('refer-friend')}
            onMouseLeave={() => setActiveItem('')}
          >
            <ReferFriendIcon
              color={activeItem == 'refer-friend' ? '#34C100' : '#6D788D'}
            />{' '}
            <span
              style={{
                color:
                  activeItem == 'refer-friend' ? '#34C100' : 'currentColor',
              }}
              className="ml-3 text-[16px]"
            >
              Refer a Friend
            </span>
          </div>
        </DropdownItem>

        <DropdownItem
          key="logout"
          color="default"
          className="flex flex-col justify-start w-full items-start"
          onPress={handleLogout}
        >
          <div
            className="flex flex-row justify-start items-center"
            onMouseEnter={() => setActiveItem('logout')}
            onMouseLeave={() => setActiveItem('')}
          >
            <LogoutIcon
              color={activeItem == 'logout' ? '#34C100' : '#6D788D'}
            />{' '}
            <span
              style={{
                color: activeItem == 'logout' ? '#34C100' : 'currentColor',
              }}
              className="ml-3 text-[16px]"
            >
              Logout
            </span>
          </div>
        </DropdownItem>

        {/* <DropdownItem key="switch">
          <DarkModeSwitch />
        </DropdownItem> */}
      </DropdownMenu>
    </Dropdown>
  );
};
