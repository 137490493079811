// components/BaseModal.tsx
import React, { ReactNode } from 'react';
import {
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
} from '@nextui-org/react';

interface BaseModalProps {
  isOpen: boolean;
  onOpenChange: (isOpen: boolean) => void;
  title: ReactNode; // Accepts any ReactNode (string, JSX, etc.) for the title
  children: ReactNode; // Content of the modal body
  footerActions?: (onClose: () => void) => ReactNode; // Optional custom footer actions
  size?:
  | 'xs'
  | 'sm'
  | 'md'
  | 'lg'
  | 'xl'
  | '2xl'
  | '3xl'
  | '4xl'
  | '5xl'
  | 'full'; // Correct size type
  modalHeaderStyles?: string;
  modalFooterStyles?: string;
  isDismissable?: boolean;
  onClose?: () => void;
  id?: string;
}

const BaseModal: React.FC<BaseModalProps> = ({
  isOpen,
  onOpenChange,
  title,
  children,
  footerActions,
  size,
  modalHeaderStyles,
  modalFooterStyles,
  isDismissable,
  onClose,
  id,
}) => {
  return (
    <Modal
      isOpen={isOpen}
      onOpenChange={onOpenChange}
      size={size}
      isDismissable={!isDismissable}
      onClose={onClose}
      id={id}
      hideCloseButton={true}
    >
      <ModalContent>
        {(onClose) => (
          <>
            <ModalHeader
              className={`flex flex-col gap-1 bg-primary text-white font-medium ${modalHeaderStyles}`}
            >
              {title}
            </ModalHeader>
            <ModalBody className="px-6">{children}</ModalBody>
            <ModalFooter
              className={`p-6 bg-light-primary-100 ${modalFooterStyles}`}
            >
              {footerActions && footerActions(onClose)}
            </ModalFooter>
          </>
        )}
      </ModalContent>
    </Modal>
  );
};

export default BaseModal;
