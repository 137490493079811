import React, { useEffect, useState } from 'react';
import { Button } from '@nextui-org/react';

// Icons
import { ExportIcon } from '@/components/icons/exports';
import { ChartIcon } from '@/components/icons/bottomnav/chart-icon';

interface ToggleTabsProps {
  id?: string;
  onTabChange: (activeTab: 'exports' | 'reports') => void;
}

const ToggleReportTabs: React.FC<ToggleTabsProps> = ({ id, onTabChange }) => {
  const [mobileView, setMobileView] = useState(false);
  const [activeTab, setActiveTab] = useState<'exports' | 'reports'>('exports');

  const handleTabChange = (tab: 'exports' | 'reports') => {
    setActiveTab(tab);
    onTabChange(tab); // Notify parent of the change
  };

  // Dynamically change the button based on screen size
  useEffect(() => {
    const updateButton = () => {
      if (window.innerWidth < 1024) {
        setMobileView(true);
      } else {
        setMobileView(false);
      }
    };

    window.addEventListener('resize', updateButton);
    updateButton(); // Initial call to set the width
    return () => window.removeEventListener('resize', updateButton);
  }, []);

  return (
    <div
      id={id}
      className="flex h-10 items-center bg-white border-2 border-solid border-light-base-primary rounded-md w-fit"
    >
      <Button
        id={`${id}-button-exports`}
        size="md"
        radius="sm"
        className={`flex items-center justify-center lg:w-[141px] rounded-l-md rounded-r-none transition-all ${
          activeTab === 'exports'
            ? 'bg-light-base-primary text-white'
            : 'bg-transparent text-light-base-primary'
        }`}
        onClick={() => handleTabChange('exports')}
        isIconOnly
      >
        <div className="flex items-center gap-1">
          <ExportIcon color={activeTab === 'exports' ? 'white' : '#34C100'} />
          {!mobileView && <span>Exports</span>}
        </div>
        {/* Exports */}
      </Button>
      <Button
        id={`${id}-button-reports`}
        size="md"
        radius="sm"
        className={`flex items-center justify-center lg:w-[141px] rounded-r-md rounded-l-none transition-all ${
          activeTab === 'reports'
            ? 'bg-light-base-primary text-white'
            : 'bg-transparent text-light-base-primary'
        }`}
        onClick={() => handleTabChange('reports')}
        isIconOnly
      >
        <div className="flex items-center gap-1">
          <ChartIcon
            color={activeTab === 'reports' ? 'white' : '#34C100'}
            height="24"
            width="24"
          />
          {!mobileView && <span>Reports</span>}
        </div>
        {/* Reports */}
      </Button>
    </div>
  );
};

export default ToggleReportTabs;
