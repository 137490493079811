'use client';
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownTrigger,
  NavbarItem,
  useDisclosure,
} from '@nextui-org/react';
import React, { useState } from 'react';
import { SettingsIcon } from '../icons/sidebar/settingsicon';
import { useSelector } from 'react-redux';
import { RootState } from '@/redux/store';
import { LogoutIcon } from '../icons/settings/tax/logout';
import { AccountSettingsIcon } from '../icons/settings/tax/account-settings';
import { CustomerPortalProfileType } from '@/helpers/types/CustomerPortalType';
import CompanyDetailsModal from '@/components/navbar/CompanyDetailsModal';

interface Props {
  referenceKey?: string;
}

export const CustomerPortalDropdown: React.FC<Props> = ({ referenceKey }) => {
  const customerData = useSelector(
    (state: RootState) => state.customerPortalProfile.customerPortalProfile,
  );
  const [activeItem, setActiveItem] = useState('');
  const [isViewCompanyDetails, setIsViewCompanyDetails] = useState(false);
  const [companyDetailsModalTitle, setCompanyDetailsModalTitle] =
    useState('Company Details');
  const { isOpen, onOpen, onOpenChange, onClose } = useDisclosure();
  const [selectedCompany, setSelectedCompany] = useState<string>('');

  const handleSignOut = async () => {
    if (window.self !== window.top) {
      // Check if the current window is not the top window (i.e., not a child frame)
      window.close();
    } else {
      // If the window can't be closed, we can instead redirect it to a logout page
      window.location.replace('https://www.zynoff.com');
    }
  };
  const handleCompanyDetailsClick = (
    selectedCustomerData: CustomerPortalProfileType,
  ) => {
    setSelectedCompany(selectedCustomerData?.company?.uuid);
    setIsViewCompanyDetails(true);
    setCompanyDetailsModalTitle('Company Details');
    onOpen();
  };

  return (
    <>
      <CompanyDetailsModal
        isOpen={isOpen}
        title={companyDetailsModalTitle}
        onOpenChange={onOpenChange}
        onClose={onClose}
        initialUuid={selectedCompany}
        referenceKey={referenceKey}
        isView={isViewCompanyDetails}
      ></CompanyDetailsModal>

      <Dropdown>
        <NavbarItem>
          <DropdownTrigger>
            <div className="cursor-pointer">
              <SettingsIcon />
            </div>
          </DropdownTrigger>
        </NavbarItem>
        <DropdownMenu
          aria-label="User menu actions"
          onAction={(actionKey) => {}}
        >
          <DropdownItem
            key="name"
            color="default"
            className="flex flex-col justify-start w-full items-start"
            showDivider
          >
            <div
              className="flex flex-col justify-start items-start"
              onMouseEnter={() => setActiveItem('name')}
              onMouseLeave={() => setActiveItem('')}
            >
              <span className="text-sm text-gray-500">Name</span>
              <span
                style={{
                  color: activeItem === 'name' ? '#34C100' : 'currentColor',
                }}
                className="text-[16px] font-medium"
              >
                {customerData?.name ? customerData.name : ''}
              </span>
            </div>
          </DropdownItem>

          <DropdownItem
            key="email"
            color="default"
            className="flex flex-col justify-start w-full items-start"
            showDivider
          >
            <div
              className="flex flex-col justify-start items-start"
              onMouseEnter={() => setActiveItem('email')}
              onMouseLeave={() => setActiveItem('')}
            >
              <span className="text-sm text-gray-500">Email</span>
              <span
                style={{
                  color: activeItem === 'email' ? '#34C100' : 'currentColor',
                }}
                className="text-[16px] font-medium"
              >
                {customerData?.email ? customerData.email : ''}
              </span>
            </div>
          </DropdownItem>

          <DropdownItem
            key="phone"
            color="default"
            className="flex flex-col justify-start w-full items-start"
            showDivider
          >
            <div
              className="flex flex-col justify-start items-start"
              onMouseEnter={() => setActiveItem('phone')}
              onMouseLeave={() => setActiveItem('')}
            >
              <span className="text-sm text-gray-500">Phone</span>
              <span
                style={{
                  color: activeItem === 'phone' ? '#34C100' : 'currentColor',
                }}
                className="text-[16px] font-medium"
              >
                {customerData?.phone ? customerData.phone : ''}
              </span>
            </div>
          </DropdownItem>

          <DropdownItem
            key="company-details"
            color="default"
            className="flex flex-col justify-start w-full items-start"
            showDivider
            onClick={() => {
              if (customerData) {
                handleCompanyDetailsClick(customerData);
              }
            }}
          >
            <div
              className="flex flex-row justify-start items-center"
              onMouseEnter={() => setActiveItem('company-details')}
              onMouseLeave={() => setActiveItem('')}
            >
              <AccountSettingsIcon
                color={activeItem == 'account-settings' ? '#34C100' : '#6D788D'}
              />{' '}
              <span
                style={{
                  color:
                    activeItem == 'company-details'
                      ? '#34C100'
                      : 'currentColor',
                }}
                className="ml-3 text-[16px]"
              >
                Company Details
              </span>
            </div>
          </DropdownItem>

          <DropdownItem
            key="signout"
            color="default"
            className="flex flex-col justify-start w-full items-start"
            onPress={handleSignOut}
          >
            <div
              className="flex flex-row justify-start items-center"
              onMouseEnter={() => setActiveItem('logout')}
              onMouseLeave={() => setActiveItem('')}
            >
              <LogoutIcon
                color={activeItem == 'signout' ? '#34C100' : '#6D788D'}
              />{' '}
              <span
                style={{
                  color: activeItem == 'signout' ? '#34C100' : 'currentColor',
                }}
                className="ml-3 text-[16px]"
              >
                Sign Out
              </span>
            </div>
          </DropdownItem>

          {/* <DropdownItem key="switch">
          <DarkModeSwitch />
        </DropdownItem> */}
        </DropdownMenu>
      </Dropdown>
    </>
  );
};
