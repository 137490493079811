import React, { useEffect, useState } from 'react';
import BaseModal from '@/components/modal/basemodal';

import { Button } from '@nextui-org/react';

import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '@/redux/store';
import { fetchSingleCompanyData } from '@/redux/thunk/customer-portal/companyThunk';
import { formatAddress } from '@/helpers/common';

interface ReusableModalProps {
  isOpen: boolean;
  title: string;
  onOpenChange: (open: boolean) => void;
  onClose: () => void;
  initialUuid?: string;
  primaryActionText?: string;
  referenceKey?: string;
  isView: boolean;
}

const CompanyDetailsModal: React.FC<ReusableModalProps> = ({
  isOpen,
  onClose,
  onOpenChange,
  title,
  initialUuid,
  primaryActionText = 'Action',
  referenceKey,
  isView,
}) => {
  const dispatch = useDispatch<AppDispatch>();
  const { selectedCompanyData } = useSelector(
    (state: RootState) => state.singleCustomerPortalCompany,
  );

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    console.log(referenceKey, 'referenceKey');
    if (isView && initialUuid && referenceKey) {
      dispatch(
        fetchSingleCompanyData({
          referenceKey: referenceKey,
          uuid: initialUuid,
        }),
      );
    }
  }, [dispatch, isView, initialUuid, onOpenChange]);

  return (
    <form action="">
      <BaseModal
        isOpen={isOpen}
        onOpenChange={onOpenChange}
        title={title}
        size="2xl"
        footerActions={
          isView
            ? (onClose) => (
                <div className="w-full">
                  <Button
                    color={'primary'}
                    fullWidth={true}
                    size={'md'}
                    radius="sm"
                    style={{ width: '100%' }}
                    variant={'bordered'}
                    disabled={isLoading}
                    onClick={onClose}
                  >
                    Close
                  </Button>
                </div>
              )
            : ''
        }
      >
        <div>
          <div className="w-full">
            <div className="flex flex-col justify-start items-start">
              <span
                className="text-3xl uppercase"
                style={{ letterSpacing: '8px' }}
              >
                {selectedCompanyData ? selectedCompanyData.name : 'N/A'}
              </span>
            </div>
          </div>

          <div className="w-full pb-3 mb-3 lg:divide-y border-b-2 boder-t-0"></div>

          <div className="w-full">
            <div className="flex flex-col justify-start items-start">
              <span className="text-sm text-gray-500">Company</span>
              <span className="text-[16px] font-medium">
                {selectedCompanyData?.name ? selectedCompanyData.name : 'N/A'}
              </span>
            </div>
          </div>

          <div className="w-full pt-5">
            <div className="flex flex-col justify-start items-start">
              <span className="text-sm text-gray-500">Phone Number</span>
              <span className="text-[16px] font-medium">
                {selectedCompanyData?.phone ? selectedCompanyData.phone : 'N/A'}
              </span>
            </div>
          </div>

          <div className="w-full pt-5">
            <div className="flex flex-col justify-start items-start">
              <span className="text-sm text-gray-500">Email</span>
              <span className="text-[16px] font-medium">
                {selectedCompanyData?.email_1
                  ? selectedCompanyData.email_1
                  : 'N/A'}
              </span>
            </div>
          </div>

          <div className="w-full pt-5">
            <div className="flex flex-col justify-start items-start">
              <span className="text-sm text-gray-500">Address</span>
              <span className="text-[16px] font-medium">
                {selectedCompanyData?.address
                  ? formatAddress(selectedCompanyData.address)
                  : 'N/A'}
              </span>
            </div>
          </div>
        </div>
      </BaseModal>
    </form>
  );
};

export default CompanyDetailsModal;
