import React from 'react';

export const AccountSettingsIcon = ({ color = 'currentColor' }) => {
  return (
    // <svg
    //   width="24"
    //   height="24"
    //   viewBox="0 0 24 24"
    //   fill="none"
    //   xmlns="http://www.w3.org/2000/svg"
    //   stroke={color}
    //   strokeWidth="2"
    //   strokeLinecap="round"
    //   strokeLinejoin="round"
    // >
    //   <path d="M18 18C19.6569 18 21 16.6569 21 15C21 13.3431 19.6569 12 18 12C16.3431 12 15 13.3431 15 15C15 16.6569 16.3431 18 18 18Z" />
    //   <path d="M9 11C11.2091 11 13 9.20914 13 7C13 4.79086 11.2091 3 9 3C6.79086 3 5 4.79086 5 7C5 9.20914 6.79086 11 9 11Z" />
    //   <path d="M10 15H6C4.93913 15 3.92172 15.4214 3.17157 16.1716C2.42143 16.9217 2 17.9391 2 19V21" />
    //   <path d="M21.7 16.4L20.8 16.1" />
    //   <path d="M15.2 13.9L14.3 13.6" />
    //   <path d="M16.6 18.7L16.9 17.8" />
    //   <path d="M19.1 12.2L19.4 11.3" />
    //   <path d="M19.6 18.7L19.2 17.7" />
    //   <path d="M16.8 12.3L16.4 11.3" />
    //   <path d="M14.3 16.6L15.3 16.2" />
    //   <path d="M20.7 13.8L21.7 13.4" />
    // </svg>

    <svg
      width="24"
      height="21"
      viewBox="0 0 24 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_d_19876_122)">
        <path
          d="M9 8V17M9 8H4.59961C4.03956 8 3.75981 8 3.5459 8.10899C3.35774 8.20487 3.20487 8.35774 3.10899 8.5459C3 8.75981 3 9.04004 3 9.6001V17H9M9 8V2.6001C9 2.04004 9 1.75981 9.10899 1.5459C9.20487 1.35774 9.35774 1.20487 9.5459 1.10899C9.75981 1 10.0396 1 10.5996 1H13.3996C13.9597 1 14.2403 1 14.4542 1.10899C14.6423 1.20487 14.7948 1.35774 14.8906 1.5459C14.9996 1.75981 15 2.04005 15 2.6001V5M9 17H15M15 17L21 17.0001V6.6001C21 6.04005 20.9996 5.75981 20.8906 5.5459C20.7948 5.35774 20.6429 5.20487 20.4548 5.10899C20.2409 5 19.9601 5 19.4 5H15M15 17V5"
          stroke={color}
          strokeWidth={2}
          strokeLinecap={'round'}
          strokeLinejoin={'round'}
          shapeRendering={'crispEdges'}
        />
      </g>
      <defs>
        <filter
          id="filter0_d_19876_122"
          x="-2"
          y="0"
          width="28"
          height="26"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters={'sRGB'}
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_19876_122"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_19876_122"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
};
